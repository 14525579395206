// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'

import { Menu, Tab, Tabs, IconButton } from '@mui/material';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Trip from 'components/commonComponents/Trip';
import { Button as ButtonMUI } from '@mui/material';

// icons
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PasswordIcon from '@mui/icons-material/Password';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Snackbar } from '@mui/material';

import ChangePassword from 'components/modals/ChangePassword';
import ChangeName from 'components/modals/ChangeName';
import ChangeEmail from 'components/modals/ChangeEmail';
import Skelton from 'components/commonComponents/Skelton';
import DeleteAccount from 'components/modals/DeleteAccount';
import RestoreAccount from 'components/modals/RestoreAccount';
import Unsubscribe from 'components/modals/Unsubscribe';
import TimeAgo from 'react-timeago'
import MenuItem from '@mui/material/MenuItem';
import AddInvitation from 'components/modals/AddInvitation';
import Button from 'components/commonComponents/Button';
import { Subscriptions, Unsubscribe as UnsubscribeIcon } from '@mui/icons-material';
import SocialShare from 'components/modals/SocialShare';

const Settings = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [myTrips, setMyTrips] = useState([]);
    const [favoriteTrips, setFavoriteTrips] = useState([]);
    const [sharedWithMe, setSharedWithMe] = useState([]);
    const [myShares, setMyShares] = useState([]);
    const [open, setOpen] = useState(false);
    const [openRestoreAccount, setOpenRestoreAccount] = useState(false);
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
    const [openPswd, setOpenPaswd] = useState(false);
    const [openName, setOpenName] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openInvitation, setOpenInvitation] = useState(false)
    const [changeImg, setChangeImage] = useState({});
    const hiddenChangeImg = useRef(null);
    const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })
    const [anchorElMenu, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(null);
    const [openDeleteTrip, setOpenDeleteTrip] = useState(false);
    const [wallet, setWallet] = useState(null)
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    const [openShareSocial, setOpenShareSocial] = useState(false);
    const [shareTitle, setshareTitle] = useState('')
    const [shareDescription, setshareDescription] = useState('')
    const [shareImage, setshareImage] = useState('')
    const [shareUrl, setshareUrl] = useState('')
    const [expiryDate, setExpiryDate] = useState('')


    const handleClickMenu = (event, value) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(value)
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
        setOpenMenu(null)
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        if (value)
            handleLogout();
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleLogout = () => {
        dispatch({
            type: 'userDetails',
            value: null
        });
        localStorage.setItem('userDetails', '');
        localStorage.setItem('sessiontoken', '');
        navigate('/');
    };

    const fetchMyTrips = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getMyTrips());
        if (res?.statusCode === 200) {
            setMyTrips(res.mytrips);
            setLoading(false)
        }
    };
    const fetchFavoriteTrips = async () => {
        const res = await fetchData(restAPIs.getFavoriteTrips());
        if (res?.statusCode === 200) {
            setFavoriteTrips(res.favourites);
        }
    };
    const fetchSharedWithMe = async () => {
        const res = await fetchData(restAPIs.getSharedWithMe());
        if (res?.statusCode === 200) {
            setSharedWithMe(res.shared);
        }
    };
    const fetchMyShares = async () => {
        const res = await fetchData(restAPIs.getMyShares());
        if (res?.statusCode === 200) {
            setMyShares(res.shared);
        }
    };

    const fetchMyWallet = async () => {
        const res = await fetchData(restAPIs.myWallet(currentLang?.id));
        setWallet(res.appInfo);
    }

    useEffect(() => {
        transformDate();
        const localDetails = JSON.parse(localStorage.getItem('userDetails'));

        if (localDetails?.email) {
            fetchMyTrips();
            fetchFavoriteTrips();
            fetchSharedWithMe();
            fetchMyShares();
            fetchMyWallet();
            appInfo();
            
        }
        else {
            dispatch({
                type: 'userDetails',
                value: null
            });
            localStorage.setItem('userDetails', '');
            localStorage.setItem('sessiontoken', '');
            navigate('/');
        }
    }, [userDetails, currentLang]);

    const handleOnClickTrip = (id) => {
        navigate('/trip/' + id);
    }

    const handleOpenChangePaswd = () => {
        setOpenPaswd(true);
    };

    const handleClosePaswd = (value) => {
        setOpenPaswd(false);
    };

    const handleOpenChangeName = () => {
        setOpenName(true);
    };
    const handleCloseName = () => {
        setOpenName(false);
    };

    const handleOpenChangeEmail = () => {
        setOpenEmail(true);
    };
    const handleCloseEmail = () => {
        setOpenEmail(false);
    };

    const handleProfileInput = (file) => {
        let files = file.target.files
        const reader = new FileReader();
        if (files.item(0)) {
            reader.readAsDataURL(files.item(0));
            reader.onload = (_event) => {
                setChangeImage({ img: reader.result, file: files.item(0) })
            }
        }
    }

    const handleOpenChangePicture = event => {
        hiddenChangeImg.current.click();
    };

    const changeProfilePic = async () => {
        const formData = new FormData();
        formData.append('profile_pic', changeImg.file)
        formData.append('appid', '29')
        const res = await fetchData(restAPIs.changeProfPic(formData));
        if (res.statusCode === 200) {
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
            let localDetails = JSON.parse(localStorage.getItem('userDetails'));
            localDetails.profile_pic = res.profile_pic + '&' + Math.random()
            dispatch({
                type: 'userDetails',
                value: localDetails
            });
            localStorage.setItem('userDetails', JSON.stringify(userDetails));
            setChangeImage({})

        } else {
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
    }

    const handleOpenDeleteAccount = () => {
        setOpenDeleteAccount(true);
    };
    const handleCloseDeleteAccount = () => {
        setOpenDeleteAccount(false);
    };

    const handleOpenRestoreAccount = () => {
        setOpenRestoreAccount(true);
    };
    const handleCloseRestoreAccount = () => {
        setOpenRestoreAccount(false);
    };

    const handleEditClick = (id) => {
        navigate('/edit-trip/' + id);
    }

    const handleDeleteClick = () => {
        setOpenDeleteTrip(true);
    };

    const handleCloseDeleteTrip = async (action, tripId, trip) => {
        setOpenDeleteTrip(false);
        if (action) {
            setAnchorEl(null);
            setOpenMenu(null)
            const formData = new FormData();
            formData.append('action', 'delete');
            formData.append('tripId', tripId);
            const res = await fetchData(restAPIs.addTrip(formData));
            if (res.statusCode === 200) {
                fetchMyTrips();
                setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            }
            else {
                setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            }
        }
    };

    const handleDeleteClickFavourite = async (tripId, trip) => {
        const formData = new FormData();
        formData.append('action', 'delete');
        formData.append('tripId', tripId);
        const res = await fetchData(restAPIs.addFavoriteTrip(formData));
        if (res.statusCode === 200) {
            fetchFavoriteTrips();
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
        else {
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }

    }

    const handleOpenInvitation = () => {
        setOpenInvitation(true);
    };

    const handleCloseInvitation = (value) => {
        setOpenInvitation(false);
    };

    const openUnsubscribe = () => {
        setShow(true);
    };

    const closeUnsubscribe = (value) => {
        setShow(false);
    };

    const appInfo = async () => {
        const res = await fetchData(restAPIs.getAppInfo(29, currentLang?.id));
        let description = transform(res.appInfo.description.replace(/<[^>]*>/g, ''), '2000');
        setshareTitle(res.appInfo.name + ' ' + res.appInfo.tagline)
        setshareDescription(res.appInfo.name + '\n' + res.appInfo.tagline + '\n' + description + '\n App Store : https://apps.apple.com/us/app/ibn-battuta/id1471975321 \n Play Store : https://play.google.com/store/apps/details?id=org.evillage.IbnBatuta \n Website : https://ibnbatuta.ae/ \n\nDiscounted Subscription :https://ibnbatuta.ae/subscribe?refcode=' + userDetails?.userid + '\n\n')
        setshareImage(res.appInfo.applicationIcon)
        setshareUrl('https://ibnbatuta.ae/subscribe?refcode=' + userDetails?.userid)
    }

    const handleOpenShareSocial = () => {
        setOpenShareSocial(true);
    };
    const handleCloseShareSocial = () => {
        setOpenShareSocial(false);
    };

    const transform = (value, args) => {
        let limit = args ? parseInt(args, 10) : 10;
        let trail = '...';
        return value.length > limit ? value.substring(0, limit) + trail : value;
    }
    
    const transformDate = () => {
        const currentDate = new Date(userDetails?.expiryDate)
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
          ];

        const day = currentDate.getDate();
        const month = currentDate.getMonth(); // Month is zero-based, so add 1
        const year = currentDate.getFullYear();
        const formattedDate = `${day} ${monthNames[month]}, ${year}`;
        setExpiryDate(formattedDate)

    }

    


    return (
        <>
            <Banner
                title={t('settings')}
                decription='Manage Passwords, accounts and lists'
                image={BannerImg}
                withSearch={false}
            />

            <div className='container mt-5'>
                <div className='__tab_wrapper'>
                    <div className='__tabs'>
                        <Tabs value={value} onChange={handleChange} orientation="vertical" sx={{ borderRight: 0, borderColor: 'none' }}>
                            <Tab className='__tab_single' label={t('settings')} {...a11yProps(0)} />
                            <Tab className='__tab_single' label={t('my_trips')} {...a11yProps(1)} />
                            <Tab className='__tab_single' label={t('favourites')} {...a11yProps(2)} />
                            <Tab className='__tab_single' label={t('shared_with_me')} {...a11yProps(3)} />
                            <Tab className='__tab_single' label={t('my_shares')} {...a11yProps(4)} />
                            <Tab className='__tab_single' label={t('my_wallet')} {...a11yProps(5)} />
                            <button onClick={handleClickOpen} className='__tab_single'>{t('sign_out')}</button>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    Log Out
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure you want to Logout.?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <a onClick={() => handleClose(false)} style={{ cursor: 'pointer' }}>Cancel</a>
                                    <a onClick={() => handleClose(true)} style={{ margin: '0 15px', fontWeight: 'bold', marginLeft: '30px', cursor: 'pointer' }}>OK</a>
                                </DialogActions>
                            </Dialog>
                        </Tabs>
                    </div>

                    <div className='__tab_content'>
                        <div
                            role="tabpanel"
                            hidden={value !== 0}
                            id={`simple-tabpanel-0`}
                            aria-labelledby={`simple-tab-0`}
                        >
                            <div className="profile">
                                <div className="prfImg">
                                    {changeImg?.img ?
                                        <img src={changeImg?.img} alt={userDetails?.username} />
                                        :
                                        <img src={userDetails?.profile_pic} alt={userDetails?.username} />
                                    }
                                    {changeImg?.img && <a className='savePic' onClick={changeProfilePic}><CheckCircleIcon /></a>}
                                </div>
                                <div className="profCont">
                                    <h3>{userDetails?.username}</h3>
                                    <p style={{ marginBottom: '30px', textAlign: 'center' }}>{userDetails?.email}</p>
                                    {userDetails && userDetails?.isPremium ?
                                        <>
                                            <button className='___subscriptioName'>{userDetails?.subscriptionName}</button>
                                            <p style={{fontSize: '16px', textAlign: 'center'}}>Expires on {expiryDate }</p>
                                        </>
                                        :''
                                    }
                                    
                                    <a style={{ borderTop: '2px solid #ddd', margin: '0 10px' }} onClick={handleOpenChangeName}><DriveFileRenameOutlineIcon style={{ margin: '0 10px' }} />{t('change_name')}</a>
                                    <a style={{ margin: '0 10px' }} onClick={handleOpenChangeEmail}><AlternateEmailIcon style={{ margin: '0 10px' }} />{t('change_email')}</a>
                                    <a style={{ margin: '0 10px' }} onClick={handleOpenChangePaswd}><PasswordIcon style={{ margin: '0 10px' }} />{t('change_password')}</a>
                                    <a style={{ margin: '0 10px' }} onClick={handleOpenChangePicture}><CameraAltIcon style={{ margin: '0 10px' }} />{t('edit_picture')}</a>
                                    <input type="file" onChange={handleProfileInput} ref={hiddenChangeImg} style={{ display: 'none' }} />

                                    <a className='___health' style={{ margin: '0 10px' }} onClick={() => navigate('/healthcheck')}>
                                        <>
                                            <svg style={{ margin: '0 10px', width: '20px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M142.4 21.9c5.6 16.8-3.5 34.9-20.2 40.5L96 71.1V192c0 53 43 96 96 96s96-43 96-96V71.1l-26.1-8.7c-16.8-5.6-25.8-23.7-20.2-40.5s23.7-25.8 40.5-20.2l26.1 8.7C334.4 19.1 352 43.5 352 71.1V192c0 77.2-54.6 141.6-127.3 156.7C231 404.6 278.4 448 336 448c61.9 0 112-50.1 112-112V265.3c-28.3-12.3-48-40.5-48-73.3c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V336c0 97.2-78.8 176-176 176c-92.9 0-168.9-71.9-175.5-163.1C87.2 334.2 32 269.6 32 192V71.1c0-27.5 17.6-52 43.8-60.7l26.1-8.7c16.8-5.6 34.9 3.5 40.5 20.2zM480 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" /></svg></>
                                        Health Check</a>

                                    {userDetails && !userDetails?.isPremium ? <a onClick={handleOpenInvitation} style={{ margin: '0 10px' }} ><ReceiptIcon style={{ margin: '0 10px' }} />{t('add_invitation_key')}</a> : ''}
                                    {userDetails && !userDetails?.isPremium ? <NavLink to={'/subscribe'} end style={{ margin: '0 10px' }} ><Subscriptions style={{ margin: '0 10px' }} />{t('subscribe')}</NavLink> : <a onClick={openUnsubscribe} style={{ margin: '0 10px' }} ><UnsubscribeIcon style={{ margin: '0 10px' }} />{t('unsubscribe')}</a>}
                                    {
                                        userDetails && (userDetails.markDeletion == 0) ?
                                            <a style={{ margin: '0 10px' }} onClick={handleOpenDeleteAccount}><DeleteIcon style={{ margin: '0 10px' }} />{t('delete_account')}</a> :
                                            <a style={{ margin: '0 10px' }} onClick={handleOpenRestoreAccount}><RestoreIcon style={{ margin: '0 10px' }} />{t('restore_account')}</a>
                                    }
                                </div>
                            </div>
                            <ChangePassword open={openPswd} onClose={handleClosePaswd} />
                            <ChangeName open={openName} onClose={handleCloseName} />
                            <ChangeEmail open={openEmail} onClose={handleCloseEmail} />
                            <AddInvitation open={openInvitation} onClose={handleCloseInvitation} />
                            <DeleteAccount open={openDeleteAccount} onClose={handleCloseDeleteAccount} />
                            <RestoreAccount open={openRestoreAccount} onClose={handleCloseRestoreAccount} />
                            <Unsubscribe open={show} onClose={closeUnsubscribe} />
                        </div>
                        <div
                            role="tabpanel"
                            hidden={value !== 1}
                            id={`simple-tabpanel-1`}
                            aria-labelledby={`simple-tab-1`}
                        >
                            <h1 className='__common_page_head'>{t('my_trips')} </h1>
                            <div className='__trips_grid_list'>

                                {
                                    isLoading ?
                                        [...Array(3)].map((item, idx) => {
                                            return (
                                                <Skelton key={idx} />
                                            )
                                        })
                                        : myTrips && myTrips.map((item, idx) => {
                                            return (
                                                <div style={{ position: 'relative' }} key={idx}>
                                                    <div key={idx + item.tripId} className='__trip_dot_icon'>
                                                        <IconButton
                                                            aria-controls={openMenu ? `basic-menu${item.tripId}` : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={openMenu ? 'true' : undefined}
                                                            onClick={(e) => handleClickMenu(e, idx)}>
                                                            <MoreVertIcon />
                                                        </IconButton>

                                                        <Menu
                                                            id={`basic-menu${item.tripId}`}
                                                            anchorEl={anchorElMenu}
                                                            open={openMenu === idx}
                                                            onClose={handleCloseMenu}
                                                            className="__trip_edit_menu"
                                                        >
                                                            <MenuItem key={idx + item.tripId + 5} onClick={() => handleEditClick(item.tripId)}>{t('edit')} </MenuItem>
                                                            <MenuItem key={idx + item.tripId + 10} onClick={() => handleDeleteClick()}>{t('delete')}</MenuItem>
                                                            <Dialog
                                                                open={openDeleteTrip}
                                                                onClose={handleCloseDeleteTrip}
                                                                aria-labelledby="alert-dialog-title"
                                                                aria-describedby="alert-dialog-description"
                                                            >
                                                                <DialogTitle id="alert-dialog-title">
                                                                    {t('delete')}
                                                                </DialogTitle>
                                                                <DialogContent>
                                                                    <DialogContentText id="alert-dialog-description">
                                                                        Are you sure you want to Remove this trip.?
                                                                    </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <a onClick={() => handleCloseDeleteTrip(false)} style={{ cursor: 'pointer' }}>{t('cancel')}</a>
                                                                    <a onClick={() => handleCloseDeleteTrip(true, item.tripId, item)} style={{ margin: '0 15px', fontWeight: 'bold', marginLeft: '30px', cursor: 'pointer' }}>{t('ok')}</a>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </Menu>
                                                    </div>
                                                    <Trip
                                                        key={idx}
                                                        title={item.tripName}
                                                        para={item.description}
                                                        image={item.coverImage}
                                                        handleOnClick={() => handleOnClickTrip(item.tripId)}
                                                        premium={item.isPremium}
                                                    />
                                                </div>
                                            )
                                        })}
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div
                            role="tabpanel"
                            hidden={value !== 2}
                            id={`simple-tabpanel-2`}
                            aria-labelledby={`simple-tab-2`}
                        >
                            <div className='__trips_grid_list'>
                                {favoriteTrips && favoriteTrips.map((item, idx) => {
                                    return (
                                        <div style={{ position: 'relative' }} key={idx}>
                                            <div key={idx + item.tripId} className='__trip_dot_icon'>
                                                <IconButton
                                                    title={t('delete')}
                                                    onClick={() => handleDeleteClickFavourite(item.tripId, item)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                            <Trip
                                                key={idx}
                                                title={item.tripName}
                                                para={item.description}
                                                image={item.coverImage}
                                                handleOnClick={() => handleOnClickTrip(item.tripId)}
                                                premium={item.isPremium}
                                            />
                                        </div>
                                    )
                                })}
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div
                            role="tabpanel"
                            hidden={value !== 3}
                            id={`simple-tabpanel-3`}
                            aria-labelledby={`simple-tab-3`}
                        >
                            <div className='__trips_grid_list'>

                                {sharedWithMe && sharedWithMe.map((item, idx) => {
                                    return (
                                        <Trip
                                            key={idx}
                                            title={item.tripName}
                                            para={item.description}
                                            image={item.coverImage}
                                            handleOnClick={() => handleOnClickTrip(item.tripId)}
                                            premium={item.isPremium}
                                        />
                                    )
                                })}
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div
                            role="tabpanel"
                            hidden={value !== 4}
                            id={`simple-tabpanel-4`}
                            aria-labelledby={`simple-tab-4`}
                        >
                            <div className='__trips_grid_list'>

                                {myShares && myShares.map((item, idx) => {
                                    return (
                                        <Trip
                                            key={idx}
                                            title={item.tripName}
                                            para={item.description}
                                            image={item.coverImage}
                                            handleOnClick={() => handleOnClickTrip(item.tripId)}
                                            premium={item.isPremium}
                                        />
                                    )
                                })}
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <div
                            role="tabpanel"
                            hidden={value !== 5}
                            id={`simple-tabpanel-5`}
                            aria-labelledby={`simple-tab-5`}
                        >
                            <div className="wallet">
                                <div className="d-flex align-items-center justify-content-between " style={{ width: '100%' }}>
                                    <div className="walletMoney ">
                                        <span>Wallet Money</span>
                                        <span className='d-flex'>
                                            <h1 style={{ margin: '0 5px' }}>{userDetails?.rewards}</h1>USD
                                        </span>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <ButtonMUI style={{ margin: '0 10px' }} onClick={handleOpenShareSocial}>Share now </ButtonMUI>
                                        <SocialShare
                                            open={openShareSocial}
                                            onClose={handleCloseShareSocial}
                                            title={shareTitle}
                                            description={shareDescription}
                                            image={shareImage}
                                            links={shareUrl}
                                            url={shareUrl}
                                            summary={shareDescription}
                                        />
                                        <Button
                                            label='Redeem'
                                        // handleOnClick={handleSave}
                                        // withLoader
                                        // loading={isLoading}
                                        // disabled={isLoading}
                                        />
                                    </div>
                                </div>

                                <div className="refEarnings ">
                                    <h4 style={{ width: '100%', textAlign: 'center' }}>{wallet?.name}</h4>
                                    <div dangerouslySetInnerHTML={{ __html: wallet?.description }}></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {errorAlert && errorAlert.open ? <Snackbar open={errorAlert.open} autoHideDuration={3000} >
                    <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
                        {errorAlert.msg}
                    </Alert>
                </Snackbar> : ''}
                <Download />
            </div>

        </>
    );
};

export default Settings;
