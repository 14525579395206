
import { Routes, BrowserRouter, Route } from 'react-router-dom';

// Components
import Header from 'components/commonComponents/Header';
import Footer from 'components/commonComponents/Footer';

import Trips from 'components/pages/Trips';
import AddTrip from 'components/pages/Trips/AddTrip';
import ViewTrip from 'components/pages/Trips/ViewTrip';
import ViewArticle from 'components/pages/Articles/ViewArticle';
import AddArticle from 'components/pages/Articles/AddArticle';
import Settings from 'components/pages/Settings';
import Articles from 'components/pages/Articles';
import Contact from 'components/pages/Contact';
import About from 'components/pages/About';
import AppDetail from 'components/pages/About/AppDetail';
import Founder from 'components/pages/About/Founder';
import Maps from 'components/pages/Maps';
import Subscription from 'components/pages/Subscription';
import SubscribePayment from 'components/pages/SubscribePayment';
import Etisalat from 'components/pages/Etisalat';
import HomePage from 'components/pages/HomePage';
import Awards from 'components/pages/Awards';
import AwardsAll from 'components/pages/Awards/AwardsAll';
import PrivacyPolicy from 'components/pages/StaticPages/PrivacyPolicy';
import BookView from 'components/pages/Awards/BookView';
import BookMap from 'components/pages/Awards/Maps';
import Search from 'components/pages/Search';
import SearchInterMediate from 'components/pages/Search/SearchInterMediate';
import HelpAndSupport from 'components/pages/HelpAndSupport';
import ChatFAQ from 'components/pages/ChatFAQ';
import Sitemap from 'components/pages/Sitemap';
import HealthCheck from 'components/pages/HealthCheck';
import Thanks from 'components/pages/Thanks';

const AppRouter = () => {
  return (
    <div>
      <BrowserRouter >
        <div>
          <Header />
          <div className="content-wrapper">
            <Routes>

              <Route path="/" element={<HomePage />} />
              <Route path="*" element={<HomePage />} />

              <Route path="/trips/:tripType" element={<Trips />} />
              <Route path="/trip/:tripId" element={<ViewTrip />} />
              <Route path="/add-trip" element={<AddTrip />} />
              <Route path="/edit-trip/:tripId" element={<AddTrip />} />

              <Route path="/article/:tripId/:articleId" element={<ViewArticle />} />
              <Route path="/add-article/:tripId" element={<AddArticle />} />
              <Route path="/edit-article/:tripId/:articleId" element={<AddArticle />} />
              <Route path="/articles/:tripId" element={<Articles />} />

              <Route path="/map/:tripId" element={<Maps />} />

              <Route path="/settings" element={<Settings />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/the-founder" element={<Founder />} />
              <Route path="/about/app/:appId" element={<AppDetail />} />

              <Route path="/subscribe" element={<Subscription />} />

              <Route path="/subscription-payment/:subId" element={<SubscribePayment />} />
              <Route path="/etisalat/:subId" element={<Etisalat />} />

              <Route path="/awards" element={<Awards />} />
              <Route path="/awards/list/:type/:typeId" element={<AwardsAll />} />
              <Route path="/book/:bookId" element={<BookView />} />
              <Route path="/book/map" element={<BookMap />} />

              <Route path="/privacy" element={<PrivacyPolicy id='59' />} />
              <Route path="/cookie" element={<PrivacyPolicy id='46' />} />
              <Route path="/terms" element={<PrivacyPolicy id='47' />} />

              <Route path='/help-and-support' element={<HelpAndSupport />} />
              <Route path='/chatFAQ' element={<ChatFAQ />} />


              <Route path='/search' element={<Search />} />
              <Route path='/search/articles/:tripId' element={<SearchInterMediate />} />

              <Route path='/sitemap' element={<Sitemap />} />
              <Route path="/thanks" element={<Thanks />} />

              <Route path="/healthcheck" element={<HealthCheck />} />

            </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
};
export default AppRouter;
